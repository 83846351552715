import { Disclosure } from "clutch/src/Accordion/Accordion.jsx";
import { Button } from "clutch/src/Button/Button.jsx";
import { Tag } from "clutch/src/Tag/Tag.jsx";

import { FakeRoute } from "@/feature-docs/docs-utils.mjs";
import { flattenPages } from "@/feature-docs/utils.mjs";
import {
  ItemBox,
  RarityBoxIcon,
  WeaponTypeIcon,
} from "@/game-fortnite/components/ItemBox.jsx";
import ProfileLPHistory from "@/game-lol/components/ProfileLPHistory.jsx";
import LolFlexQueue from "@/inline-assets/hextech-queue-flex.svg";
import LolRGM from "@/inline-assets/hextech-queue-rgm.svg";
import StarIcon from "@/inline-assets/star.svg";
import { SquareAvatar } from "@/shared/Avatar.jsx";
import DataTable, {
  DataTableAsync,
  DataTablePaginated,
} from "@/shared/DataTable.jsx";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import { ProfileColumn } from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import { StatsCard, StatsTile } from "@/shared/StatsCard.jsx";

interface Page {
  title: string;
  component: `feature-docs/${string}.${"md" | "mdx"}`;
  docsFor?: Record<string, unknown>;
}

interface Folder {
  group: string;
  pages: [Page, ...Page[]];
}

// TODO: defer load docsFor components & prolly wrap in suspense
const pages: (Folder | Page)[] = [
  {
    title: "Home",
    component: "feature-docs/docs-home.md",
  },
  {
    title: "Initial Decisions",
    component: "feature-docs/docs-initial-decisions.md",
  },
  {
    title: "Architecture",
    component: "feature-docs/docs-architecture.mdx",
  },
  {
    title: "Design Principles",
    component: "feature-docs/docs-design-principles.md",
  },
  {
    group: "Guides",
    pages: [
      {
        title: "App State",
        component: "feature-docs/guide-app-state.md",
      },
      {
        title: "Data Model",
        component: "feature-docs/guide-data-model.md",
      },
      {
        title: "Feature Flags",
        component: "feature-docs/guide-feature-flags.md",
      },
      {
        title: "Router",
        component: "feature-docs/guide-router.md",
      },
      {
        title: "Get Data",
        component: "feature-docs/guide-get-data.md",
      },
      {
        title: "GraphQL",
        component: "feature-docs/guide-graphql-templating.md",
      },
      {
        title: "i18n",
        component: "feature-docs/guide-i18n.md",
      },
      {
        title: "Electron Overlays",
        component: "feature-docs/guide-electron-overlays.md",
      },
      {
        title: "Symbols",
        component: "feature-docs/guide-symbols.md",
      },
      {
        title: "AB Testing",
        component: "feature-docs/guide-ab-testing.mdx",
      },
      {
        title: "Appx: Bugs",
        component: "feature-docs/appendix-bugs.md",
      },
      {
        title: "Appx: State Management",
        component: "feature-docs/appendix-state-management.md",
      },
      {
        title: "Appx: Why?",
        component: "feature-docs/appendix-why.md",
      },
      {
        title: "Appx: Why Not ____?",
        component: "feature-docs/appendix-why-not.md",
      },
    ],
  },
  {
    group: "Styling",
    pages: [
      {
        title: "Styling (CSS)",
        component: "feature-docs/guide-styling.mdx",
      },
      {
        title: "Container Queries (CSS)",
        component: "feature-docs/guide-container-queries.mdx",
      },
      {
        title: "Sizing/Spacing",
        component: "feature-docs/theme-spacing.mdx",
      },
      {
        title: "Colors",
        component: "feature-docs/theme-colors.mdx",
      },
    ],
  },
  {
    group: "Features",
    pages: [
      {
        title: "Ads",
        component: "feature-docs/feature-ads.mdx",
      },
      {
        title: "Battles",
        component: "feature-docs/feature-battles.mdx",
      },
      {
        title: "Subscriber",
        component: "feature-docs/feature-subscriber.md",
      },
      {
        title: "Wallet",
        component: "feature-docs/feature-wallet.mdx",
      },
    ],
  },
  {
    group: "Clutch",
    pages: [
      {
        title: "Button",
        component: "feature-docs/clutch-button.mdx",
        docsFor: { Button },
      },
      {
        title: "Disclosure",
        component: "feature-docs/clutch-accordion.mdx",
        docsFor: { Disclosure },
      },
      {
        title: "Tag",
        component: "feature-docs/clutch-tag.mdx",
        docsFor: { Tag },
      },
    ],
  },
  {
    group: "Fortnite",
    pages: [
      {
        title: "Components",
        component: "feature-docs/game-fortnite-components.mdx",
        docsFor: { RarityBoxIcon, ItemBox, WeaponTypeIcon },
      },
    ],
  },
  {
    group: "Common Components",
    pages: [
      {
        title: "Avatar",
        component: "feature-docs/component-avatar.mdx",
        docsFor: { SquareAvatar, StarIcon },
      },
      {
        title: "Data Table",
        component: "feature-docs/component-data-table.mdx",
        docsFor: {
          DataTable,
          DataTablePaginated,
          DataTableAsync,
          FakeRoute,
        },
      },
      {
        title: "Stats Card",
        component: "feature-docs/component-stats-card.mdx",
        docsFor: { StatsCard, StatsTile, StarIcon },
      },
    ],
  },
  {
    group: "Profile Components",
    pages: [
      {
        title: "Profile Rank",
        component: "feature-docs/component-profile-rank.mdx",
        docsFor: {
          ProfileRank,
          ProfileLPHistory,
          ProfileColumn,
          LolFlexQueue,
          LolRGM,
        },
      },
      {
        title: "Expandable Match Tile",
        component: "feature-docs/component-match-tile.mdx",
        docsFor: { MatchTileExpand },
      },
    ],
  },
];

export default pages.map((p) => flattenPages(p));
